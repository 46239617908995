<template>
  <ca-card class="mb-3">
    <template #header>
      <div class="row">
        <div class="col d-flex align-items-center">
          <h4 class="m-0">
            Forderung
          </h4>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-primary"
            @click="addPayment"
          >
            <i class="fas fa-plus mr-2" />
            Zahlung hinzufügen
          </button>
        </div>
      </div>
    </template>
    <template #body>
      <div v-if="damagePayment.length > 0">
        <damage-request-item
          v-for="(payment, index) in damagePayment"
          :key="payment._id"
          ref="DamageRequestItem"
          :payment-details="payment"
          :index="index"
          @remove-payment="removePayment"
          @update-info="updatePayment"
        />
      </div>
      <div
        v-else
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <i class="fad fa-file-invoice text-primary fa-4x mb-3" />
        <p>Klicke auf  <i class="fas fa-plus" /> um Zahlungen zu erstellen.</p>
      </div>
      <transition-group name="slide-fade">
        <div
          v-if="!isCalc && damagePayment.length > 0"
          key="1"
        >
          <basic-input
            v-model="damageReport.ZAHLUNG.SSB"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Selbstbehalt"
            type="number"
            @input="calculateResults"
          >
            <template #append>
              <span class="input-group-text">€</span>
            </template>
          </basic-input>

          <div class="form-row pb-3">
            <div class="col-12 col-md-8">
              <basic-input
                v-model="damageReport.ZAHLUNG.ZGS"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="KV Betrag (Netto)"
                :margin="false"
                placeholder="KV Betrag (Netto)"
                disabled
                type="number"
              >
                <template #append>
                  <span class="input-group-text">€</span>
                </template>
              </basic-input>
            </div>
            <div class="col-12 col-md-4">
              <basic-input
                v-model="damageReport.ZAHLUNG.ERSTBTRG"
                :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
                :horizontal="true"
                :margin="false"
                placeholder="Erstattung (Netto)"
                disabled
                type="number"
              >
                <template #append>
                  <span class="input-group-text">€</span>
                </template>
              </basic-input>
            </div>
          </div>

          <div class="form-row pb-3">
            <div class="col-12 col-md-8">
              <basic-input
                v-model="damageReport.ZAHLUNG.MWSTPROZ"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="MwSt."
                :margin="false"
                placeholder="MwSt."
                type="number"
                min="0"
                max="100"
                @input="calculateResults"
              >
                <template #append>
                  <span class="input-group-text">%</span>
                </template>
              </basic-input>
            </div>
            <div class="col-12 col-md-2">
              <basic-input
                v-model="damageReport.ZAHLUNG.ZGS_MWST"
                :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
                :horizontal="true"
                :margin="false"
                placeholder="KV MwSt."
                disabled
                type="number"
              >
                <template #append>
                  <span class="input-group-text">€</span>
                </template>
              </basic-input>
            </div>
            <div class="col-12 col-md-2">
              <basic-input
                v-model="damageReport.ZAHLUNG.ERSTBTRG_MWST"
                :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
                :horizontal="true"
                :margin="false"
                placeholder="Erst. MwSt."
                disabled
                type="number"
              >
                <template #append>
                  <span class="input-group-text">€</span>
                </template>
              </basic-input>
            </div>
          </div>

          <div class="form-row pb-3">
            <div class="col-12 col-md-8">
              <basic-input
                v-model="damageReport.ZAHLUNG.ZGS_BRUTTO"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="KV Betrag (Brutto)"
                :margin="false"
                placeholder="KV Betrag (Brutto)"
                disabled
                type="number"
              >
                <template #append>
                  <span class="input-group-text">€</span>
                </template>
              </basic-input>
            </div>
            <div class="col-12 col-md-4">
              <basic-input
                v-model="damageReport.ZAHLUNG.ERSTBTRG_BRUTTO"
                :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
                :horizontal="true"
                :margin="false"
                placeholder="Erstattung (Brutto)"
                disabled
                type="number"
              >
                <template #append>
                  <span class="input-group-text">€</span>
                </template>
              </basic-input>
            </div>
          </div>

          <div class="form-row pb-3">
            <div class="col-12 col-md-4">
              Vorsteuerabzug
            </div>
            <div class="col-12 col-md-8 d-flex align-items-center">
              <basic-checkbox
                v-model="damageReport.ZAHLUNG.VSA"
                :margin="false"
                @input="calculateResults"
              />
            </div>
          </div>

          <basic-input
            v-model="damageReport.ZAHLUNG.ERSTBTRG_NB"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Erstattung"
            :margin="false"
            placeholder=""
            disabled
          >
            <template #append>
              <span class="input-group-text">€</span>
            </template>
          </basic-input>
        </div>
        <div
          v-else-if="isCalc"
          key="2"
          class="d-flex justify-content-center align-items-center"
        >
          <i class="fad fa-spinner-third fa-spin fa-3x text-primary mr-3" />
          <h5 class="m-0">
            Lade Berechnungen...
          </h5>
        </div>
      </transition-group>
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card.vue'
import DamageRequestItem from './DamageRequestItem.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import debounce from 'lodash.debounce'

export default {
  name: 'DamageRequest',
  components: {
    CaCard,
    DamageRequestItem,
    BasicInput,
    BasicCheckbox
  },
  props: {
    damageReport: {
      type: Object,
      required: true
    },
    damagePayment: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isCalc: false
    }
  },
  watch: {
    damagePayment: {
      deep: true,
      handler () {
        this.calculateResults()
      }
    }
  },
  mounted () {
    if (this.$route.params.reportId) {
      for (const payment of this.$refs.DamageRequestItem) {
        if (payment.index !== 0) {
          payment.visible = false
        }
      }
    }
  },
  methods: {
    addPayment () {
      if (this.$refs.DamageRequestItem) {
        for (const item of this.$refs.DamageRequestItem) {
          item.visible = false
        }
      }
      this.damagePayment.push(new this.$FeathersVuex.api.DamagePayments())
    },
    removePayment (index) {
      this.damagePayment.splice(index, 1)
      if (index > 0) {
        this.$refs.DamageRequestItem[index - 1].visible = true
      }
    },
    updatePayment (payment, index) {
      if (!this.isCalc) {
        this.debouncedUpdatePayment(payment, index)
      }
    },
    debouncedUpdatePayment: debounce(function (payment, index) {
      this.damagePayment.splice(index, 1, payment)
      this.$forceUpdate()
    }, 500),
    calculateResults () {
      // Calculate KV netto
      const result = {
        ZGS: 0,
        ERSTBTRG: 0
      }
      for (const payment of this.damagePayment) {
        console.log((result.ZGS * 100) + (payment.Zahlung * 100))
        if (parseInt(payment.Zahlung)) {
          result.ZGS = +result.ZGS + +payment.Zahlung
          result.ERSTBTRG = +result.ERSTBTRG + +payment.Erstattung
        }
      }
      this.damageReport.ZAHLUNG.ZGS = result.ZGS.toFixed(2)
      this.damageReport.ZAHLUNG.ERSTBTRG = result.ERSTBTRG.toFixed(2)
      // Calculate MWST & brutto
      if (this.damageReport.ZAHLUNG.MWSTPROZ) {
        if (this.damageReport.ZAHLUNG && this.damageReport.ZAHLUNG.ZGS) {
          this.damageReport.ZAHLUNG.ZGS_MWST = (+this.damageReport.ZAHLUNG.MWSTPROZ / 100 * this.damageReport.ZAHLUNG.ZGS).toFixed(2)
        } else {
          this.$delete(this.damageReport.ZAHLUNG, 'ZGS_MWST')
        }
        if (this.damageReport.ZAHLUNG && this.damageReport.ZAHLUNG.ERSTBTRG) {
          this.damageReport.ZAHLUNG.ERSTBTRG_MWST = (+this.damageReport.ZAHLUNG.MWSTPROZ / 100 * this.damageReport.ZAHLUNG.ERSTBTRG).toFixed(2)
        } else {
          this.$delete(this.damageReport.ZAHLUNG, 'ERSTBTRG_MWST')
        }
        if (this.damageReport.ZAHLUNG && this.damageReport.ZAHLUNG.ZGS_MWST && this.damageReport.ZAHLUNG.ERSTBTRG_MWST) {
          this.damageReport.ZAHLUNG.ZGS_BRUTTO = (+this.damageReport.ZAHLUNG.ZGS + +this.damageReport.ZAHLUNG.ZGS_MWST).toFixed(2)
          this.damageReport.ZAHLUNG.ERSTBTRG_BRUTTO = (+this.damageReport.ZAHLUNG.ERSTBTRG + +this.damageReport.ZAHLUNG.ERSTBTRG_MWST).toFixed(2)
        } else {
          this.$delete(this.damageReport.ZAHLUNG, 'ZGS_BRUTTO')
          this.$delete(this.damageReport.ZAHLUNG, 'ERSTBTRG_BRUTTO')
        }
      }
      // Calculate final refund
      if (this.damageReport.ZAHLUNG.SSB && this.damageReport.ZAHLUNG.ERSTBTRG) {
        if (this.damageReport.ZAHLUNG.VSA) {
          this.damageReport.ZAHLUNG.ERSTBTRG_NB = (+this.damageReport.ZAHLUNG.ERSTBTRG - +this.damageReport.ZAHLUNG.SSB).toFixed(2)
        } else {
          this.damageReport.ZAHLUNG.ERSTBTRG_NB = (+this.damageReport.ZAHLUNG.ERSTBTRG_BRUTTO - +this.damageReport.ZAHLUNG.SSB).toFixed(2)
        }
      } else {
        this.$delete(this.damageReport.ZAHLUNG, 'ERSTBTRG_NB')
      }
    }
  }
}
</script>
