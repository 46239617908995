module.exports = {
  1: { api: '1', human: 'Motor' },
  2: { api: '2', human: 'Getriebe' },
  3: { api: '3', human: 'Turbolader' },
  4: { api: '4', human: 'AGR-Ventil' },
  5: { api: '5', human: 'Kühlsystem' },
  6: { api: '6', human: 'Heizgebläse' },
  7: { api: '7', human: 'Lenkgetriebe' },
  8: { api: '8', human: 'Servopumpe' },
  9: { api: '9', human: 'Injektor' },
  A: { api: 'A', human: 'Anlasser' },
  B: { api: 'B', human: 'Ansaugkrümmer' },
  C: { api: 'C', human: 'Kraftübertragungswelle' },
  D: { api: 'D', human: 'Abgassystem' },
  E: { api: 'E', human: 'Komfortelektronik' },
  F: { api: 'F', human: 'Kraftstoffsystem' },
  G: { api: 'G', human: 'Interieur' },
  H: { api: 'H', human: 'Werkstoffe' },
  I: { api: 'I', human: 'Generator' },
  J: { api: 'J', human: 'Bremssystem' },
  K: { api: 'K', human: 'Klimaanlage' },
  L: { api: 'L', human: 'Airbag' },
  M: { api: 'M', human: 'Elektrische Anlage' }
}
