<template>
  <Modal
    v-if="show"
    size="md"
    title="Schadensmeldung speichern"
    :closable="true"
    @close="$emit('close')"
  >
    <template #body>
      <CaAlert
        v-if="showFormInvalidHint"
        variant="danger"
        class="mb-2"
        message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
        @close="showFormInvalidHint = null"
      />
      <BasicInput
        v-model="username"
        :columns="{label: ['col-12', 'col-md-5'], input:['col-12', 'col-md-7']}"
        :horizontal="true"
        label="Bearbeiter"
        disabled
        :margin="false"
      />
      <BasicInput
        v-model="log.SV_BES"
        :columns="{label: ['col-12', 'col-md-5'], input:['col-12', 'col-md-7']}"
        :horizontal="true"
        label="Kommentar zur Änderung"
        placeholder="Kommentar"
        required
      />
      <p class="m-0 text-gray">
        Die Änderungen werden zusammen mit dem Kommentar im Versionsverlauf eingetragen.
      </p>
    </template>
    <template #footer>
      <button
        class="btn btn-primary float-right ml-2"
        @click="savePending = true"
      >
        Speichern <i
          v-if="savePending"
          class="fas fa-circle-notch fa-spin"
        />
      </button>
      <button
        class="btn hover-gray-2 text-primary float-right"
        @click="$emit('close')"
      >
        Abbrechen
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import CaAlert from '@/components/Alert.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import moment from 'moment'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    CaAlert,
    BasicInput
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      username: null,
      log: null,
      savePending: false,
      showFormInvalidHint: false
    }
  },
  computed: {
    me () {
      return this.$store.getters['user/auth']
    }
  },
  watch: {
    savePending: {
      async handler () {
        if (this.savePending) {
          const valid = await this.$validator.validateAll()
          try {
            if (!valid) {
              this.showFormInvalidHint = true
              return
            }
            this.$emit('save', this.log)
          } catch (e) {
            console.error(e)
            this.savePending = false
          } finally {
            this.savePending = false
          }
        }
      }
    },
    show: {
      handler () {
        if (this.show) {
          const user = this.$store.getters['auth/user'].user
          this.log = new this.$FeathersVuex.api.DamageReportsLogs()
          this.log.SV_DAT = moment()
          this.log.SV_BET = user._id
          this.username = `${user.firstname} ${user.lastname}`
        }
      }
    }
  }
}
</script>
