<template>
  <div>
    <SaveDamageReportEditModal
      :show="showSaveModal"
      @save="save"
      @close="showSaveModal = false"
    />
    <CaHeader
      :heading="`${damageReport ? ((damageReport.SCHADEN.SNR_MAKLER || damageReport.SCHADEN.SNR) + ' bearbeiten' || '') : 'Lade...'}`"
      :column-layout="columnLayout"
      show-column-layout
      :loading="loading"
      @columnLayout="columnLayout = $event"
    >
      <template #buttonSpace>
        <button
          class="btn btn-primary mr-2"
          @click="showSaveModal = true"
        >
          Speichern <i
            v-if="savePending"
            class="fas fa-circle-notch fa-spin"
          />
        </button>
      </template>
      <template #alert>
        <CaAlert
          v-if="saveErrorMessage"
          variant="danger"
          :message="saveErrorMessage"
          @close="saveErrorMessage = null"
        />
        <CaAlert
          v-if="showFormInvalidHint"
          variant="info"
          message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
          @close="showFormInvalidHint = null"
        />
      </template>
      <template #customLoading>
        <CaAlert
          variant="info"
          spin-icon
          rounded
          icon="circle-notch"
          message="Lade Schaden..."
        />
      </template>
    </CaHeader>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div
          class="col-12"
          :class="{'col-xl-8 col-xxl-6': !columnLayout}"
        >
          <div class="row">
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <DamageForm :damage-report="damageReport" />
            </div>
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <DamageEvent
                ref="damageEvent"
                :damage-report="damageReport"
              />
            </div>
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <DamageRequest
                :damage-report="damageReport"
                :damage-payment="damagePayment"
              />
            </div>
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <DamageReason
                :damage-report="damageReport"
                :edit-mode="true"
              />
            </div>
          </div>
          <div
            v-if="!loading"
            class="form-row justify-content-end"
          >
            <button
              class="btn btn-primary mr-2"
              @click="showSaveModal = true"
              @save="save()"
            >
              Speichern <i
                v-if="savePending"
                class="fas fa-circle-notch fa-spin"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header.vue'
import DamageForm from '@/components/Forms/DamageReport/DamageForm.vue'
import DamageEvent from '@/components/Forms/DamageReport/DamageEvent.vue'
import DamageRequest from '@/components/Forms/DamageReport/DamageRequest.vue'
import DamageReason from '@/components/Forms/DamageReport/DamageReason.vue'
import SaveDamageReportEditModal from '@/components/Modals/SaveDamageReportEditModal.vue'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'DamageReportEdit',
  components: {
    CaHeader,
    DamageForm,
    DamageEvent,
    DamageRequest,
    DamageReason,
    SaveDamageReportEditModal
  },
  data () {
    return {
      damageReport: null,
      damagePayment: [],
      columnLayout: false,
      loading: false,
      showSaveModal: false,
      savePending: false,
      saveErrorMessage: null,
      saveSuccess: false,
      showFormInvalidHint: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.damageReport = await this.$store.dispatch('damage-reports/get', this.$route.params.reportId)
        const damagePaymentArray = await this.$store.dispatch('damage-payments/find', {
          query: {
            reportId: this.$route.params.reportId
          }
        })
        this.damageReport = this.damageReport.clone()
        this.damagePayment = [...damagePaymentArray.data]
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async save (log) {
      try {
        const valid = await this.$validator.validateAll()
        if (this.savePending) {
          return
        } else if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.damageReport.FIELDS.userId = this.$store.getters['auth/user'].user._id
        this.savePending = true
        this.saveSuccess = false
        this.saveErrorMessage = null
        const savedReport = await this.damageReport.save()
        await this.saveDamageLog(savedReport, log)
        await this.saveDamagePayment(savedReport)
        this.saveSuccess = true
      } catch (e) {
        console.error(e)
        this.saveErrorMessage = e.message
      } finally {
        this.savePending = false
        if (this.saveSuccess) {
          this.$router.push({ name: 'DamageReportDetail', params: { reportId: this.damageReport._id } })
        }
      }
    },
    async saveDamagePayment (report) {
      try {
        for (const payment of this.damagePayment) {
          payment.reportId = report._id
          await payment.save()
        }
      } catch (e) {
        console.error(e)
        return e
      }
    },
    async saveDamageLog (report, log) {
      try {
        log.reportId = report._id
        await log.save()
      } catch (e) {
        console.error(e)
        return e
      }
    }
  }
}

</script>
