<template>
  <div class="form-row mb-3">
    <div class="col-12 col-md-4">
      Grund {{ index + 1 }}
    </div>
    <div class="col-12 col-md-8">
      <basic-wysiwyg
        v-model="text"
        :file-upload="false"
      />
    </div>
  </div>
</template>

<script>
import BasicWysiwyg from '@/components/BaseComponents/BasicWysiwyg.vue'

export default {
  name: 'DamageReasonItem',
  components: {
    BasicWysiwyg
  },
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      text: ''
    }
  },
  watch: {
    text: {
      handler () {
        this.$emit('updateText', this.index, this.text)
      }
    }
  }
}
</script>
