<template>
  <b-card
    no-body
    header
    class="mb-2"
  >
    <template #header>
      <div
        class="row"
        @click="visible = !visible"
      >
        <div class="col d-flex align-items-center">
          <h6 class="mb-0">
            Zahlung {{ index + 1 }}
          </h6>
        </div>
        <div class="col-auto">
          <button
            class="btn bg-transparent p-0"
          >
            <i
              class="far"
              :class="{'fa-chevron-down': visible, 'fa-chevron-left': !visible}"
            />
          </button>
        </div>
      </div>
    </template>
    <b-card-body :class="{'p-0': !visible}">
      <b-collapse
        id="zahlung"
        v-model="visible"
      >
        <form
          novalidate
          @submit.prevent
        >
          <div class="form-row pt-3">
            <div class="col-12 col-md-8">
              <basic-datepicker
                v-model="paymentDetailsInner.ZahlDat"
                :horizontal="true"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                label="Datum / Zahlungsart"
                placeholder="TT.MM.JJJJ"
                :margin="false"
                required
              />
            </div>
            <div class="col-12 col-md-4">
              <basic-select
                :id="'paymentMethod' + index"
                v-model="paymentDetailsInner.Art"
                :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
                placeholder="Zahlungsart"
                :horizontal="true"
                :margin="false"
                class="pb-3"
                :options="humanPaymentTypes"
                required
              />
            </div>
          </div>

          <basic-input
            v-model="paymentDetailsInner.Zahlung"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="RG-Betrag"
            type="number"
            required
            @input="setRefund()"
          >
            <template #append>
              <span class="input-group-text">€</span>
            </template>
          </basic-input>

          <div class="form-row pb-3">
            <div class="col-12 col-md-8">
              <basic-input
                v-model="paymentDetailsInner.ErstattungProzent"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                :horizontal="true"
                label="Erstattung"
                :margin="false"
                type="number"
                max="100"
                min="0"
                @input="setRefund()"
              >
                <template #append>
                  <span class="input-group-text">%</span>
                </template>
              </basic-input>
            </div>
            <div class="col-12 col-md-4">
              <basic-input
                v-model="paymentDetailsInner.Erstattung"
                :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
                :horizontal="true"
                :margin="false"
                disabled
              >
                <template #append>
                  <span class="input-group-text">€</span>
                </template>
              </basic-input>
            </div>
          </div>

          <basic-input
            v-model="paymentDetailsInner.An"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Zahlungsempfänger"
            :margin="false"
          />

          <div class="form-row py-3">
            <div class="col-12 col-md-4">
              Bemerkung
            </div>
            <div
              class="col-12 col-md-8"
            >
              <basic-wysiwyg
                v-model="paymentDetailsInner.Bemerkung"
                :file-upload="false"
              />
            </div>
          </div>

          <basic-select
            :id="'paymentstatus' + index"
            v-model="paymentDetailsInner.Status"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Status"
            label="Status"
            :horizontal="true"
            :margin="false"
            class="pb-3"
            :options="humanDamageStatusTypes"
          />
        </form>
        <div
          class="row"
        >
          <div class="col d-flex justify-content-end">
            <button
              class="btn btn-outline-danger"
              @click="$emit('remove-payment', index)"
            >
              <i class="far fa-trash-alt mr-2" /> Zahlung entfernen
            </button>
          </div>
        </div>
      </b-collapse>
    </b-card-body>
  </b-card>
</template>
<script>
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import BasicWysiwyg from '@/components/BaseComponents/BasicWysiwyg.vue'
import damagePayment from '@/resources/enums/damage/damagePayment.js'
import damagePayStatus from '@/resources/enums/damage/damagePayStatus.js'

export default {
  name: 'DamageRequestItem',
  components: {
    BasicInput,
    BasicDatepicker,
    BasicSelect,
    BasicWysiwyg
  },
  props: {
    paymentDetails: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      visible: true,
      damagePayment,
      damagePayStatus,
      paymentDetailsInner: null
    }
  },
  computed: {
    humanPaymentTypes () {
      return Object.values(this.damagePayment).map(payment => ({ label: payment.human, value: payment.api }))
    },
    humanDamageStatusTypes () {
      return Object.values(this.damagePayStatus).map(status => ({ label: status.human, value: status.api }))
    }
  },
  watch: {
    paymentDetailsInner: {
      deep: true,
      handler () {
        this.$emit('update-info', this.paymentDetailsInner, this.index)
      }
    }
  },
  created () {
    this.paymentDetailsInner = this.paymentDetails.clone()
  },
  methods: {
    setRefund () {
      if (this.paymentDetails.Zahlung && this.paymentDetails.ErstattungProzent) {
        this.paymentDetails.Erstattung = (this.paymentDetails.ErstattungProzent / 100 * this.paymentDetails.Zahlung).toFixed(2)
      } else {
        this.$delete(this.paymentDetails, 'Erstattung')
      }
      this.$forceUpdate()
    }
  }
}
</script>
