<template>
  <ca-card class="mb-3">
    <template #header>
      <div class="row">
        <div class="col d-flex align-items-center">
          <h4 class="m-0">
            Begründung
          </h4>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-primary"
            @click="addReason"
          >
            <i class="fas fa-plus mr-2" />
            Begründung hinzufügen
          </button>
        </div>
      </div>
    </template>
    <template #body>
      <div v-if="damageReport.ZAHLUNG.GRUND.length > 0">
        <damage-reason-item
          v-for="(reason, index) in damageReport.ZAHLUNG.GRUND"
          ref="reasonItem"
          :key="index"
          :index="index"
          @updateText="updateText"
        />
      </div>
      <div
        v-else
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <i class="fad fa-comment-alt-dots fa-4x mb-3 text-primary" />
        <p>Klicke auf  <i class="fas fa-plus" /> um Begründungen zu erstellen.</p>
      </div>
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card.vue'
import DamageReasonItem from '@/components/Forms/DamageReport/DamageReasonItem.vue'

export default {
  name: 'DamageReason',
  components: {
    CaCard,
    DamageReasonItem
  },
  props: {
    damageReport: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    addReason () {
      this.damageReport.ZAHLUNG.GRUND.push('')
    },
    updateText (index, text) {
      this.damageReport.ZAHLUNG.GRUND[index] = text
    }
  }
}
</script>
