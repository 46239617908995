module.exports = {
  Abgaskrümmer: {
    api: 'Abgaskrümmer',
    human: 'Abgaskrümmer'
  },
  Abgassystem: {
    api: 'Abgassystem',
    human: 'Abgassystem'
  },
  Abgastemperatursensor: {
    api: 'Abgastemperatursensor',
    human: 'Abgastemperatursensor'
  },
  'ABS Hydraulikeinheit': {
    api: 'ABS Hydraulikeinheit',
    human: 'ABS Hydraulikeinheit'
  },
  'ABS Sensor': {
    api: 'ABS Sensor',
    human: 'ABS Sensor'
  },
  'AD Blue': {
    api: 'AD Blue',
    human: 'AD Blue'
  },
  'AGR Ventil': {
    api: 'AGR Ventil',
    human: 'AGR Ventil'
  },
  'AGR-Kühler': {
    api: 'AGR-Kühler',
    human: 'AGR-Kühler'
  },
  Airbag: {
    api: 'Airbag',
    human: 'Airbag'
  },
  Airbagschalter: {
    api: 'Airbagschalter',
    human: 'Airbagschalter'
  },
  Airmatic: {
    api: 'Airmatic',
    human: 'Airmatic'
  },
  Akustikwarner: {
    api: 'Akustikwarner',
    human: 'Akustikwarner'
  },
  Anlasser: {
    api: 'Anlasser',
    human: 'Anlasser'
  },
  Ansaugkrümmer: {
    api: 'Ansaugkrümmer',
    human: 'Ansaugkrümmer'
  },
  Ansaugkrümmerdichtung: {
    api: 'Ansaugkrümmerdichtung',
    human: 'Ansaugkrümmerdichtung'
  },
  Antenne: {
    api: 'Antenne',
    human: 'Antenne'
  },
  Antrieb: {
    api: 'Antrieb',
    human: 'Antrieb'
  },
  Antriebswelle: {
    api: 'Antriebswelle',
    human: 'Antriebswelle'
  },
  Antriebswellengelenk: {
    api: 'Antriebswellengelenk',
    human: 'Antriebswellengelenk'
  },
  Anwaltspauschale: {
    api: 'Anwaltspauschale',
    human: 'Anwaltspauschale'
  },
  Aufprallsensor: {
    api: 'Aufprallsensor',
    human: 'Aufprallsensor'
  },
  Außenspiegel: {
    api: 'Außenspiegel',
    human: 'Außenspiegel'
  },
  Ausgleichswelle: {
    api: 'Ausgleichswelle',
    human: 'Ausgleichswelle'
  },
  Auspuffkrümmer: {
    api: 'Auspuffkrümmer',
    human: 'Auspuffkrümmer'
  },
  Ausrücklager: {
    api: 'Ausrücklager',
    human: 'Ausrücklager'
  },
  Automatikgetriebe: {
    api: 'Automatikgetriebe',
    human: 'Automatikgetriebe'
  },
  Batterie: {
    api: 'Batterie',
    human: 'Batterie'
  },
  Beleuchtung: {
    api: 'Beleuchtung',
    human: 'Beleuchtung'
  },
  Blinker: {
    api: 'Blinker',
    human: 'Blinker'
  },
  Bordcomputer: {
    api: 'Bordcomputer',
    human: 'Bordcomputer'
  },
  Bremsanlage: {
    api: 'Bremsanlage',
    human: 'Bremsanlage'
  },
  Bremslichtschalter: {
    api: 'Bremslichtschalter',
    human: 'Bremslichtschalter'
  },
  Bremssattel: {
    api: 'Bremssattel',
    human: 'Bremssattel'
  },
  Dichtungen: {
    api: 'Dichtungen',
    human: 'Dichtungen'
  },
  Dieselpartikelfilter: {
    api: 'Dieselpartikelfilter',
    human: 'Dieselpartikelfilter'
  },
  Differenzdrucksensor: {
    api: 'Differenzdrucksensor',
    human: 'Differenzdrucksensor'
  },
  Differenzial: {
    api: 'Differenzial',
    human: 'Differenzial'
  },
  Display: {
    api: 'Display',
    human: 'Display'
  },
  Domlager: {
    api: 'Domlager',
    human: 'Domlager'
  },
  Doppelkupplung: {
    api: 'Doppelkupplung',
    human: 'Doppelkupplung'
  },
  Dosierventil: {
    api: 'Dosierventil',
    human: 'Dosierventil'
  },
  Drehmomentwandler: {
    api: 'Drehmomentwandler',
    human: 'Drehmomentwandler'
  },
  Drehwinkelsensor: {
    api: 'Drehwinkelsensor',
    human: 'Drehwinkelsensor'
  },
  Drehzahlsensor: {
    api: 'Drehzahlsensor',
    human: 'Drehzahlsensor'
  },
  Drosselklappe: {
    api: 'Drosselklappe',
    human: 'Drosselklappe'
  },
  'Drosselklappen Stellmotor': {
    api: 'Drosselklappen Stellmotor',
    human: 'Drosselklappen Stellmotor'
  },
  Druckplatte: {
    api: 'Druckplatte',
    human: 'Druckplatte'
  },
  Druckregelventil: {
    api: 'Druckregelventil',
    human: 'Druckregelventil'
  },
  Druckschalter: {
    api: 'Druckschalter',
    human: 'Druckschalter'
  },
  Drucksensor: {
    api: 'Drucksensor',
    human: 'Drucksensor'
  },
  Einspritzanlage: {
    api: 'Einspritzanlage',
    human: 'Einspritzanlage'
  },
  Einspritzdüse: {
    api: 'Einspritzdüse',
    human: 'Einspritzdüse'
  },
  Einspritzpumpe: {
    api: 'Einspritzpumpe',
    human: 'Einspritzpumpe'
  },
  Elektrik: {
    api: 'Elektrik',
    human: 'Elektrik'
  },
  'erhöhter Ölverbrauch': {
    api: 'erhöhter Ölverbrauch',
    human: 'erhöhter Ölverbrauch'
  },
  Expertise: {
    api: 'Expertise',
    human: 'Expertise'
  },
  Fahrpedalsensor: {
    api: 'Fahrpedalsensor',
    human: 'Fahrpedalsensor'
  },
  Feder: {
    api: 'Feder',
    human: 'Feder'
  },
  Federbein: {
    api: 'Federbein',
    human: 'Federbein'
  },
  Fensterheber: {
    api: 'Fensterheber',
    human: 'Fensterheber'
  },
  Flexrohr: {
    api: 'Flexrohr',
    human: 'Flexrohr'
  },
  Geberzylinder: {
    api: 'Geberzylinder',
    human: 'Geberzylinder'
  },
  Generator: {
    api: 'Generator',
    human: 'Generator'
  },
  'Generator Freilauf': {
    api: 'Generator Freilauf',
    human: 'Generator Freilauf'
  },
  Getriebe: {
    api: 'Getriebe',
    human: 'Getriebe'
  },
  Glühsteuergerät: {
    api: 'Glühsteuergerät',
    human: 'Glühsteuergerät'
  },
  'GPS Empfänger': {
    api: 'GPS Empfänger',
    human: 'GPS Empfänger'
  },
  Halter: {
    api: 'Halter',
    human: 'Halter'
  },
  Handbremsseil: {
    api: 'Handbremsseil',
    human: 'Handbremsseil'
  },
  Heckleuchte: {
    api: 'Heckleuchte',
    human: 'Heckleuchte'
  },
  Heckscheibe: {
    api: 'Heckscheibe',
    human: 'Heckscheibe'
  },
  Hecktürgriff: {
    api: 'Hecktürgriff',
    human: 'Hecktürgriff'
  },
  Heizelement: {
    api: 'Heizelement',
    human: 'Heizelement'
  },
  Heizgebläse: {
    api: 'Heizgebläse',
    human: 'Heizgebläse'
  },
  Hochdruckpumpe: {
    api: 'Hochdruckpumpe',
    human: 'Hochdruckpumpe'
  },
  Hybridbatterie: {
    api: 'Hybridbatterie',
    human: 'Hybridbatterie'
  },
  Hydraulikventil: {
    api: 'Hydraulikventil',
    human: 'Hydraulikventil'
  },
  Hydrostößel: {
    api: 'Hydrostößel',
    human: 'Hydrostößel'
  },
  Impulsgeber: {
    api: 'Impulsgeber',
    human: 'Impulsgeber'
  },
  Injektor: {
    api: 'Injektor',
    human: 'Injektor'
  },
  Kardanwelle: {
    api: 'Kardanwelle',
    human: 'Kardanwelle'
  },
  Katalysator: {
    api: 'Katalysator',
    human: 'Katalysator'
  },
  Keilriemen: {
    api: 'Keilriemen',
    human: 'Keilriemen'
  },
  Kettenspanner: {
    api: 'Kettenspanner',
    human: 'Kettenspanner'
  },
  Klimaanlage: {
    api: 'Klimaanlage',
    human: 'Klimaanlage'
  },
  Klimakompressor: {
    api: 'Klimakompressor',
    human: 'Klimakompressor'
  },
  Klimakompressorkupplung: {
    api: 'Klimakompressorkupplung',
    human: 'Klimakompressorkupplung'
  },
  Klopfsensor: {
    api: 'Klopfsensor',
    human: 'Klopfsensor'
  },
  Kolbenringe: {
    api: 'Kolbenringe',
    human: 'Kolbenringe'
  },
  Kombiinstrument: {
    api: 'Kombiinstrument',
    human: 'Kombiinstrument'
  },
  Kombischalter: {
    api: 'Kombischalter',
    human: 'Kombischalter'
  },
  Komfortelektrik: {
    api: 'Komfortelektrik',
    human: 'Komfortelektrik'
  },
  Kompressor: {
    api: 'Kompressor',
    human: 'Kompressor'
  },
  Kondensator: {
    api: 'Kondensator',
    human: 'Kondensator'
  },
  Kopfdichtung: {
    api: 'Kopfdichtung',
    human: 'Kopfdichtung'
  },
  Koppelstangen: {
    api: 'Koppelstangen',
    human: 'Koppelstangen'
  },
  Kraftstoffanlage: {
    api: 'Kraftstoffanlage',
    human: 'Kraftstoffanlage'
  },
  Kraftstoffpumpe: {
    api: 'Kraftstoffpumpe',
    human: 'Kraftstoffpumpe'
  },
  Kraftübertragung: {
    api: 'Kraftübertragung',
    human: 'Kraftübertragung'
  },
  Kugelgelenk: {
    api: 'Kugelgelenk',
    human: 'Kugelgelenk'
  },
  Kühlerlüfter: {
    api: 'Kühlerlüfter',
    human: 'Kühlerlüfter'
  },
  Kühlmittelrohr: {
    api: 'Kühlmittelrohr',
    human: 'Kühlmittelrohr'
  },
  Kühlmittelsensor: {
    api: 'Kühlmittelsensor',
    human: 'Kühlmittelsensor'
  },
  Kühlsystem: {
    api: 'Kühlsystem',
    human: 'Kühlsystem'
  },
  Kupplung: {
    api: 'Kupplung',
    human: 'Kupplung'
  },
  Kupplungsgeberzylinder: {
    api: 'Kupplungsgeberzylinder',
    human: 'Kupplungsgeberzylinder'
  },
  Kupplungsnehmerzylinder: {
    api: 'Kupplungsnehmerzylinder',
    human: 'Kupplungsnehmerzylinder'
  },
  Kupplungsschalter: {
    api: 'Kupplungsschalter',
    human: 'Kupplungsschalter'
  },
  Kurbelwelle: {
    api: 'Kurbelwelle',
    human: 'Kurbelwelle'
  },
  Kurbelwellenlager: {
    api: 'Kurbelwellenlager',
    human: 'Kurbelwellenlager'
  },
  Kurbelwellensensor: {
    api: 'Kurbelwellensensor',
    human: 'Kurbelwellensensor'
  },
  Ladedrucksensor: {
    api: 'Ladedrucksensor',
    human: 'Ladedrucksensor'
  },
  Ladedrucksystem: {
    api: 'Ladedrucksystem',
    human: 'Ladedrucksystem'
  },
  Ladeluftkühler: {
    api: 'Ladeluftkühler',
    human: 'Ladeluftkühler'
  },
  Lambdasonde: {
    api: 'Lambdasonde',
    human: 'Lambdasonde'
  },
  Lampenträger: {
    api: 'Lampenträger',
    human: 'Lampenträger'
  },
  Lautsprecher: {
    api: 'Lautsprecher',
    human: 'Lautsprecher'
  },
  Leitung: {
    api: 'Leitung',
    human: 'Leitung'
  },
  Lenkradverriegelung: {
    api: 'Lenkradverriegelung',
    human: 'Lenkradverriegelung'
  },
  Lenkung: {
    api: 'Lenkung',
    human: 'Lenkung'
  },
  Lichtsteuergerät: {
    api: 'Lichtsteuergerät',
    human: 'Lichtsteuergerät'
  },
  Lüftermotor: {
    api: 'Lüftermotor',
    human: 'Lüftermotor'
  },
  Luftfeder: {
    api: 'Luftfeder',
    human: 'Luftfeder'
  },
  Luftfederbein: {
    api: 'Luftfederbein',
    human: 'Luftfederbein'
  },
  Luftfilter: {
    api: 'Luftfilter',
    human: 'Luftfilter'
  },
  Luftmassenmesser: {
    api: 'Luftmassenmesser',
    human: 'Luftmassenmesser'
  },
  Magnetkupplung: {
    api: 'Magnetkupplung',
    human: 'Magnetkupplung'
  },
  Magnetventil: {
    api: 'Magnetventil',
    human: 'Magnetventil'
  },
  Mantelrohrmodul: {
    api: 'Mantelrohrmodul',
    human: 'Mantelrohrmodul'
  },
  Mechatronik: {
    api: 'Mechatronik',
    human: 'Mechatronik'
  },
  Modul: {
    api: 'Modul',
    human: 'Modul'
  },
  Motor: {
    api: 'Motor',
    human: 'Motor'
  },
  Motorhaubenschloss: {
    api: 'Motorhaubenschloss',
    human: 'Motorhaubenschloss'
  },
  Motorkontrolleuchte: {
    api: 'Motorkontrolleuchte',
    human: 'Motorkontrolleuchte'
  },
  Motorlager: {
    api: 'Motorlager',
    human: 'Motorlager'
  },
  Motorsteuergerät: {
    api: 'Motorsteuergerät',
    human: 'Motorsteuergerät'
  },
  Navigationsgerät: {
    api: 'Navigationsgerät',
    human: 'Navigationsgerät'
  },
  Nehmerzylinder: {
    api: 'Nehmerzylinder',
    human: 'Nehmerzylinder'
  },
  Niveauregulierung: {
    api: 'Niveauregulierung',
    human: 'Niveauregulierung'
  },
  Nockenwelle: {
    api: 'Nockenwelle',
    human: 'Nockenwelle'
  },
  Nockenwellenversteller: {
    api: 'Nockenwellenversteller',
    human: 'Nockenwellenversteller'
  },
  'NOX-Sensor': {
    api: 'NOX-Sensor',
    human: 'NOX-Sensor'
  },
  Ölabscheider: {
    api: 'Ölabscheider',
    human: 'Ölabscheider'
  },
  Öldruckschalter: {
    api: 'Öldruckschalter',
    human: 'Öldruckschalter'
  },
  Ölfiltergehäuse: {
    api: 'Ölfiltergehäuse',
    human: 'Ölfiltergehäuse'
  },
  Ölkühler: {
    api: 'Ölkühler',
    human: 'Ölkühler'
  },
  Ölpumpe: {
    api: 'Ölpumpe',
    human: 'Ölpumpe'
  },
  Ölstandssensor: {
    api: 'Ölstandssensor',
    human: 'Ölstandssensor'
  },
  Ölverlust: {
    api: 'Ölverlust',
    human: 'Ölverlust'
  },
  Pedalsensor: {
    api: 'Pedalsensor',
    human: 'Pedalsensor'
  },
  Programmierung: {
    api: 'Programmierung',
    human: 'Programmierung'
  },
  'Pumpe-Düse-Einheit': {
    api: 'Pumpe-Düse-Einheit',
    human: 'Pumpe-Düse-Einheit'
  },
  Querlenker: {
    api: 'Querlenker',
    human: 'Querlenker'
  },
  Radbremszylinder: {
    api: 'Radbremszylinder',
    human: 'Radbremszylinder'
  },
  Radio: {
    api: 'Radio',
    human: 'Radio'
  },
  Radlager: {
    api: 'Radlager',
    human: 'Radlager'
  },
  Radnabe: {
    api: 'Radnabe',
    human: 'Radnabe'
  },
  Raildrucksensor: {
    api: 'Raildrucksensor',
    human: 'Raildrucksensor'
  },
  Rechtsanwalt: {
    api: 'Rechtsanwalt',
    human: 'Rechtsanwalt'
  },
  Riemenscheibe: {
    api: 'Riemenscheibe',
    human: 'Riemenscheibe'
  },
  Rückfahrkamera: {
    api: 'Rückfahrkamera',
    human: 'Rückfahrkamera'
  },
  Rückspiegel: {
    api: 'Rückspiegel',
    human: 'Rückspiegel'
  },
  Saugrohrklappe: {
    api: 'Saugrohrklappe',
    human: 'Saugrohrklappe'
  },
  Schalter: {
    api: 'Schalter',
    human: 'Schalter'
  },
  Schaltseilzug: {
    api: 'Schaltseilzug',
    human: 'Schaltseilzug'
  },
  Schaltübertragung: {
    api: 'Schaltübertragung',
    human: 'Schaltübertragung'
  },
  'Schaltzentrum Lenksäule': {
    api: 'Schaltzentrum Lenksäule',
    human: 'Schaltzentrum Lenksäule'
  },
  Scheibenwaschpumpe: {
    api: 'Scheibenwaschpumpe',
    human: 'Scheibenwaschpumpe'
  },
  Scheinwerfer: {
    api: 'Scheinwerfer',
    human: 'Scheinwerfer'
  },
  Schlauch: {
    api: 'Schlauch',
    human: 'Schlauch'
  },
  Schloss: {
    api: 'Schloss',
    human: 'Schloss'
  },
  Schwungrad: {
    api: 'Schwungrad',
    human: 'Schwungrad'
  },
  Sensor: {
    api: 'Sensor',
    human: 'Sensor'
  },
  Sensormatte: {
    api: 'Sensormatte',
    human: 'Sensormatte'
  },
  Servopumpe: {
    api: 'Servopumpe',
    human: 'Servopumpe'
  },
  Signalhorn: {
    api: 'Signalhorn',
    human: 'Signalhorn'
  },
  Simmering: {
    api: 'Simmering',
    human: 'Simmering'
  },
  Sitzbelegung: {
    api: 'Sitzbelegung',
    human: 'Sitzbelegung'
  },
  Sitzgebläse: {
    api: 'Sitzgebläse',
    human: 'Sitzgebläse'
  },
  Sitzgestell: {
    api: 'Sitzgestell',
    human: 'Sitzgestell'
  },
  Sitzheizung: {
    api: 'Sitzheizung',
    human: 'Sitzheizung'
  },
  Software: {
    api: 'Software',
    human: 'Software'
  },
  Spannrolle: {
    api: 'Spannrolle',
    human: 'Spannrolle'
  },
  Spiegelheizung: {
    api: 'Spiegelheizung',
    human: 'Spiegelheizung'
  },
  Standheizung: {
    api: 'Standheizung',
    human: 'Standheizung'
  },
  Stellmotor: {
    api: 'Stellmotor',
    human: 'Stellmotor'
  },
  Steuergerät: {
    api: 'Steuergerät',
    human: 'Steuergerät'
  },
  Steuerkette: {
    api: 'Steuerkette',
    human: 'Steuerkette'
  },
  Steuerklappe: {
    api: 'Steuerklappe',
    human: 'Steuerklappe'
  },
  Stopplichtschalter: {
    api: 'Stopplichtschalter',
    human: 'Stopplichtschalter'
  },
  Stoßdämpfer: {
    api: 'Stoßdämpfer',
    human: 'Stoßdämpfer'
  },
  Stoßstange: {
    api: 'Stoßstange',
    human: 'Stoßstange'
  },
  Tankgeber: {
    api: 'Tankgeber',
    human: 'Tankgeber'
  },
  Temperaturgeber: {
    api: 'Temperaturgeber',
    human: 'Temperaturgeber'
  },
  Tempomat: {
    api: 'Tempomat',
    human: 'Tempomat'
  },
  Thermostat: {
    api: 'Thermostat',
    human: 'Thermostat'
  },
  Thermostatgehäuse: {
    api: 'Thermostatgehäuse',
    human: 'Thermostatgehäuse'
  },
  Trockner: {
    api: 'Trockner',
    human: 'Trockner'
  },
  Turbolader: {
    api: 'Turbolader',
    human: 'Turbolader'
  },
  Türschloss: {
    api: 'Türschloss',
    human: 'Türschloss'
  },
  'Twin Air Einheit': {
    api: 'Twin Air Einheit',
    human: 'Twin Air Einheit'
  },
  Ultraschallwandler: {
    api: 'Ultraschallwandler',
    human: 'Ultraschallwandler'
  },
  Umlenkrolle: {
    api: 'Umlenkrolle',
    human: 'Umlenkrolle'
  },
  Umwälzpumpe: {
    api: 'Umwälzpumpe',
    human: 'Umwälzpumpe'
  },
  Unterdruckvorratsbehälter: {
    api: 'Unterdruckvorratsbehälter',
    human: 'Unterdruckvorratsbehälter'
  },
  Vakuumpumpe: {
    api: 'Vakuumpumpe',
    human: 'Vakuumpumpe'
  },
  Ventil: {
    api: 'Ventil',
    human: 'Ventil'
  },
  Verdampfer: {
    api: 'Verdampfer',
    human: 'Verdampfer'
  },
  Vorglührelais: {
    api: 'Vorglührelais',
    human: 'Vorglührelais'
  },
  Warnmeldung: {
    api: 'Warnmeldung',
    human: 'Warnmeldung'
  },
  Wasserablauf: {
    api: 'Wasserablauf',
    human: 'Wasserablauf'
  },
  Wasserkühler: {
    api: 'Wasserkühler',
    human: 'Wasserkühler'
  },
  Wasserpumpe: {
    api: 'Wasserpumpe',
    human: 'Wasserpumpe'
  },
  Wischermotor: {
    api: 'Wischermotor',
    human: 'Wischermotor'
  },
  'Xenon Teile': {
    api: 'Xenon Teile',
    human: 'Xenon Teile'
  },
  Xenonlampe: {
    api: 'Xenonlampe',
    human: 'Xenonlampe'
  },
  Xenonmodul: {
    api: 'Xenonmodul',
    human: 'Xenonmodul'
  },
  Xenonscheinwerfer: {
    api: 'Xenonscheinwerfer',
    human: 'Xenonscheinwerfer'
  },
  Zahnriemen: {
    api: 'Zahnriemen',
    human: 'Zahnriemen'
  },
  Zentralveriegelung: {
    api: 'Zentralveriegelung',
    human: 'Zentralveriegelung'
  },
  Zuheizer: {
    api: 'Zuheizer',
    human: 'Zuheizer'
  },
  Zündanlage: {
    api: 'Zündanlage',
    human: 'Zündanlage'
  },
  Zündanlasschalter: {
    api: 'Zündanlasschalter',
    human: 'Zündanlasschalter'
  },
  Zündkabel: {
    api: 'Zündkabel',
    human: 'Zündkabel'
  },
  Zündschloss: {
    api: 'Zündschloss',
    human: 'Zündschloss'
  },
  Zündspule: {
    api: 'Zündspule',
    human: 'Zündspule'
  },
  Zweimassenschwungrad: {
    api: 'Zweimassenschwungrad',
    human: 'Zweimassenschwungrad'
  },
  Zylinderkopf: {
    api: 'Zylinderkopf',
    human: 'Zylinderkopf'
  },
  Zylinderkopfdichtung: {
    api: 'Zylinderkopfdichtung',
    human: 'Zylinderkopfdichtung'
  },
  Zylinderkopfhaube: {
    api: 'Zylinderkopfhaube',
    human: 'Zylinderkopfhaube'
  }
}
