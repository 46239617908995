<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Hergang und Notizen
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent
      >
        <div class="form-row pb-3">
          <div class="col-12 col-md-4">
            Schadenhergang
          </div>
          <div
            class="col-12 col-md-8"
          >
            <basic-wysiwyg
              v-model="damageReport.INFO.BE1"
              :file-upload="false"
            />
          </div>
        </div>

        <div class="form-row pb-3">
          <div class="col-12 col-md-4">
            Interne Bermerkung
          </div>
          <div
            class="col-12 col-md-8"
          >
            <basic-wysiwyg
              v-model="damageReport.INFO.BE_INTERN"
              :file-upload="false"
            />
          </div>
        </div>

        <div class="form-row py-3">
          <div class="col-12 col-md-4">
            Weitere Notizen
          </div>
          <div class="col-12 col-md-8 d-flex align-items-center">
            <basic-switch
              v-model="addNote"
              :margin="false"
              :swtich-state-name="{active: 'Aktiv', inactive: 'Inaktiv'}"
            />
          </div>
        </div>

        <b-collapse
          id="notizen"
          v-model="addNote"
        >
          <div class="form-row pb-3">
            <div class="col-12 col-md-4" />
            <div
              class="col-12 col-md-8"
            >
              <basic-wysiwyg
                v-model="damageReport.INFO.SCHADEN_NOTIZ"
                :file-upload="false"
              />
            </div>
          </div>
        </b-collapse>

        <basic-datepicker
          v-model="damageReport.INFO.AST_SSD"
          :horizontal="true"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          label="Schaden-Service"
          placeholder="TT.MM.JJJJ"
          :margin="false"
        />

        <div class="form-row py-2">
          <div class="col-12 col-md-4 d-flex align-items-center">
            Gutachter beauftragt
          </div>
          <div class="col-12 col-md-8 d-flex align-items-center">
            <div class="d-flex align-items-center w-100">
              <basic-checkbox
                v-model="expert"
                :margin="false"
                class="mr-2"
              />
              <transition name="slide-fade">
                <basic-input
                  v-if="expert"
                  v-model="damageReport.SCHADEN.AST_GUT_NAM"
                  :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
                  placeholder="Gutachter Name"
                  :horizontal="true"
                  :margin="false"
                  class="w-100"
                />
              </transition>
            </div>
          </div>
        </div>
      </form>
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card.vue'
import BasicWysiwyg from '@/components/BaseComponents/BasicWysiwyg.vue'
import BasicSwitch from '@/components/BaseComponents/BasicSwitch.vue'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'

export default {
  name: 'DamageEvent',
  components: {
    CaCard,
    BasicWysiwyg,
    BasicSwitch,
    BasicCheckbox,
    BasicInput,
    BasicDatepicker
  },
  props: {
    damageReport: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      addNote: false,
      service: false,
      expert: false
    }
  },
  created () {
    if (this.$route.params.reportId) {
      this.addNote = !!this.damageReport.INFO.SCHADEN_NOTIZ
      this.service = !!this.damageReport.INFO.AST_SSD
      this.expert = !!this.damageReport.INFO.AST_GUT_NAM
    }
  }
}
</script>
