<template>
  <CaCard class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Schadensdaten
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        autocomplete="off"
        @submit.prevent
      >
        <BasicTypeahead
          id="notAFieldForAutocorrectionPopUpChrome"
          v-model="owner"
          autocomplete="notAFieldForAutocorrectionPopUpChrome"
          strict
          immediate
          :query="searchOwnerByName"
          :input-formatter="owner => `${owner.lastname} ${owner.firstname}`"
          :result-formatter="owner => `${owner.lastname} ${owner.firstname}, ${owner.street}, ${owner.city.zip} ${owner.city.name}`"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Halter"
          :horizontal="true"
          label="Halter"
          required
          @input="deleteOrder"
          @hit="setCars"
        />

        <transition name="slide-fade">
          <div v-if="cars.length > 0">
            <div class="form-row mb-3">
              <div class="col-12 col-md-8">
                <BasicSelect
                  id="cars"
                  v-model="damageReport.FIELDS.orderId"
                  placeholder="Bitte auswählen"
                  :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                  label="Fahrzeug / Kennzeichen"
                  :horizontal="true"
                  :margin="false"
                  class="pb-3"
                  :options="cars"
                  required
                />
              </div>
              <div
                v-if="order"
                class="col-12 col-md-4"
              >
                <BasicInput
                  v-model="order.car.licensePlate"
                  :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
                  :horizontal="true"
                  :margin="false"
                  disabled
                />
              </div>
              <div
                v-else
                class="col-12 col-md-4"
              >
                <BasicInput
                  :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
                  :horizontal="true"
                  :margin="false"
                  disabled
                />
              </div>
            </div>
          </div>
        </transition>

        <transition name="slide-fade">
          <div v-if="orderVisible">
            <div class="form-row mb-3">
              <div class="col-12 col-md-8">
                <BasicInput
                  v-model="insuranceCompany"
                  :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                  :horizontal="true"
                  :margin="false"
                  label="Versicherer / Garantienummer"
                  disabled
                />
              </div>
              <div class="col-12 col-md-4">
                <BasicInput
                  v-model="order.contract.warrantyNumber"
                  :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
                  :horizontal="true"
                  :margin="false"
                  disabled
                />
              </div>
            </div>

            <BasicSelect
              id="schadenart"
              v-model="damageReport.SCHADEN.SAR"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Schadenart"
              :horizontal="true"
              :margin="false"
              label="Schadenart"
              class="pb-3"
              :options="humanDamageTypes"
              :required="damageReport.FIELDS.orderId ? true : false"
            />

            <BasicSelect
              id="schadenart2"
              v-model="damageReport.SCHADEN.SAR2"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Schadenart 2"
              :horizontal="true"
              :margin="false"
              :options="humanDamageTypes"
            />

            <div class="form-row my-3">
              <div class="col-12 col-md-8">
                <BasicDatepicker
                  v-model="damageReport.SCHADEN.SDA"
                  :horizontal="true"
                  :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                  label="Schadentag"
                  placeholder="TT.MM.JJJJ"
                  :margin="false"
                  :required="damageReport.FIELDS.orderId ? true : false"
                />
              </div>
              <div class="col-12 col-md-4">
                <div class="d-flex justify-content-center align-items-center">
                  <BasicSelect
                    v-model="timeHours"
                    :columns="{input:['col-12', 'col-md-12']}"
                    placeholder="HH"
                    :options="hours"
                    :horizontal="true"
                    :margin="false"
                    :disabled="!damageReport.SCHADEN.SDA"
                    @input="setTime"
                  />
                  <p class="mx-1">
                    :
                  </p>
                  <BasicSelect
                    v-model="timeMinutes"
                    :columns="{input:['col-12', 'col-md-12']}"
                    placeholder="MM"
                    :options="minutes"
                    :horizontal="true"
                    :margin="false"
                    :disabled="!damageReport.SCHADEN.SDA"
                    @input="setTime"
                  />
                </div>
              </div>
            </div>

            <BasicInput
              v-model="order.car.km"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Kilometerstand"
              :horizontal="true"
              label="KM bei Garantieabschluss"
              disabled
            >
              <template #append>
                <span class="input-group-text">km</span>
              </template>
            </BasicInput>

            <BasicInput
              v-model="damageReport.SCHADEN.KMS_SMELD"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="Kilometerstand"
              :horizontal="true"
              label="KM bei Schadenmeldung"
              type="number"
              :min="order.car.km || 0"
              :required="damageReport.FIELDS.orderId ? true : false"
            >
              <template #append>
                <span class="input-group-text">km</span>
              </template>
            </BasicInput>

            <div
              v-if="kmdiv"
              class="form-row pb-3"
            >
              <div class="col-12 col-md-4" />
              <div class="col-12 col-md-8 d-flex align-items-center">
                <small>Differenz: {{ kmdiv }} km</small>
              </div>
            </div>

            <BasicTypeahead
              id="typeahead-werkstatt"
              v-model="workshop"
              :query="searchWorkshopByName"
              :input-formatter="workshop => `${workshop.address.NAME.NAM}`"
              :result-formatter="workshop => `${workshop.address.NAME.NAM}, ${workshop.address.STR}, ${workshop.address.PLZ} ${workshop.address.ORT}`"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              :horizontal="true"
              label="Werkstatt"
              :margin="false"
              placeholder="Werkstatt suchen"
              @input="$delete(damageReport.FIELDS, 'workshopId')"
              @hit="setWorkshop"
            />
          </div>
        </transition>

        <hr>

        <div class="form-row py-3">
          <div class="col-12 col-md-4">
            Ist erledigt
          </div>
          <div class="col-12 col-md-8 d-flex align-items-center">
            <BasicCheckbox
              v-model="isDone"
              :margin="false"
            />
          </div>
        </div>

        <b-collapse
          id="erledigt"
          v-model="isDone"
        >
          <div class="form-row py-3">
            <div class="col-12 col-md-8">
              <BasicDatepicker
                v-model="damageReport.SCHADEN.ERLEDIGT"
                :horizontal="true"
                :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
                label="Erledigt am / Bezahlt am"
                placeholder="Erledigt am (TT.MM.JJJJ)"
                :margin="false"
                :required="isDone"
              />
            </div>
            <div class="col-12 col-md-4">
              <BasicDatepicker
                v-model="damageReport.SCHADEN.BEZAHLTA"
                :horizontal="true"
                :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
                placeholder="Bezahlt am (TT.MM.JJJJ)"
                :margin="false"
                :required="isDone"
              />
            </div>
          </div>
        </b-collapse>

        <BasicSelect
          id="schadenstatus"
          v-model="damageReport.SCHADEN.STATUS"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Status"
          label="Status"
          :horizontal="true"
          :margin="false"
          class="pb-3"
          :options="humanDamageStatus"
        />

        <BasicSelect
          id="sendenan"
          v-model="damageReport.SCHADEN.SENDENAN"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Auswählen"
          label="Senden an"
          :horizontal="true"
          :margin="false"
          class="pb-3"
          :options="humanDamageSendTo"
        />

        <BasicSelect
          id="rgauf"
          v-model="damageReport.SCHADEN.RGAUF"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Auswählen"
          label="Rechnung an"
          :horizontal="true"
          :margin="false"
          :options="humanDamageInvoice"
        />

        <hr>

        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <BasicDatepicker
              v-model="damageReport.SCHADEN.MEL"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Gemeldet am"
              placeholder="TT.MM.JJJJ"
              :margin="false"
              required
            />
          </div>
          <div class="col-12 col-md-4">
            <BasicInput
              v-model="damageReport.SCHADEN.VON"
              :columns="{label: ['col-12'], input:['col-12', 'col-md-12']}"
              placeholder="Gemeldet von"
              :margin="false"
              :horizontal="true"
            />
          </div>
        </div>

        <BasicSelect
          id="gemeldetMit"
          v-model="damageReport.SCHADEN.contactType"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Kontaktart"
          :horizontal="true"
          :margin="false"
          :options="humanContactOptions"
          required
        />

        <hr>

        <BasicInput
          v-model="damageReport.SCHADEN.SNR"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Alternative Schaden-Nr."
          :horizontal="true"
          label="Alternative Schaden-Nr."
          :margin="false"
        />
      </form>
    </template>
  </CaCard>
</template>

<script>
import CaCard from '@/components/Card.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead.vue'
import damageType from '@/resources/enums/damage/damageType.js'
import contactType from '@/resources/enums/contactType.js'
import insuranceType from '@/resources/enums/insuranceType.js'
import damageStatus from '@/resources/enums/damage/damageStatus.js'
import damageSendTo from '@/resources/enums/damage/damageSendTo.js'
import damageInvoice from '@/resources/enums/damage/damageInvoice.js'
import combineDateTime from '@/helpers/combineDateTime.js'
import formatDate from '@/filters/formatDate.js'
import feathers from '@/api'
export default {
  name: 'DamageForm',
  components: {
    CaCard,
    BasicInput,
    BasicSelect,
    BasicDatepicker,
    BasicCheckbox,
    BasicTypeahead
  },
  props: {
    damageReport: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      workshop: '',
      owner: '',
      order: '',
      orderVisible: false,
      cars: [],
      kontaktart: '',
      damageType,
      contactType,
      insuranceType,
      damageStatus,
      damageSendTo,
      damageInvoice,
      timeHours: 0,
      timeMinutes: 0,
      showWorkshopModal: false,
      isDone: false
    }
  },
  computed: {
    humanDamageTypes () {
      return Object.values(this.damageType).map(damage => ({ label: damage.human, value: damage.api }))
    },
    humanContactOptions () {
      return Object.values(this.contactType).map(contact => ({ label: contact.human, value: contact.api }))
    },
    humanDamageStatus () {
      return Object.values(this.damageStatus).map(status => ({ label: status.human, value: status.api }))
    },
    humanDamageSendTo () {
      return Object.values(this.damageSendTo).map(sendTo => ({ label: sendTo.human, value: sendTo.api }))
    },
    humanDamageInvoice () {
      return Object.values(this.damageInvoice).map(invoice => ({ label: invoice.human, value: invoice.api }))
    },
    hours () {
      return Array.from(Array(24).keys()).map(hours => ({ label: (hours < 10 ? '0' : '') + hours, value: (hours < 10 ? '0' : '') + hours }))
    },
    minutes () {
      return Array.from(Array(60).keys()).map(minutes => ({ label: (minutes < 10 ? '0' : '') + minutes, value: (minutes < 10 ? '0' : '') + minutes }))
    },
    kmdiv () {
      if (this.order.car.km && this.damageReport.SCHADEN.KMS_SMELD) {
        const diff = this.damageReport.SCHADEN.KMS_SMELD - this.order.car.km
        return diff > 0 ? diff : null
      } else {
        return null
      }
    },
    insuranceCompany: {
      get () {
        return this.insuranceType[this.order.product.rate.versicherer].human
      },
      set (value) {
        this.order.product.rate.versicherer = value
      }
    }
  },
  watch: {
    'damageReport.FIELDS.orderId': {
      immediate: true,
      async handler (orderId) {
        if (orderId) {
          const order = await this.$store.dispatch('orders/get', orderId)
          this.order = order
          this.orderVisible = true
        }
      }
    },
    'damageReport.SCHADEN': {
      immediate: true,
      handler () {
        if (!this.isDone && (this.damageReport.SCHADEN.ERLEDIGT || this.damageReport.SCHADEN.BEZAHLTA)) {
          this.isDone = true
        }
      }
    }
  },
  created: async function () {
    if (this.$route.params.reportId) {
      this.order = await this.$store.dispatch('orders/get', this.damageReport.FIELDS.orderId)
      this.owner = `${this.order.owner.lastname} ${this.order.owner.firstname}`
      if (this.damageReport.FIELDS.workshopId) {
        this.workshop = await this.$store.dispatch('workshops/get', this.damageReport.FIELDS.workshopId)
      }
      this.timeHours = formatDate(this.damageReport.SCHADEN.SDA, 'HH')
      this.timeMinutes = formatDate(this.damageReport.SCHADEN.SDA, 'mm')
      this.orderVisible = true
    }
  },
  methods: {
    formatDate,
    closeWorkshopModal () {
      this.showWorkshopModal = false
    },
    searchOwnerByName (name) {
      return feathers.service('owner-distinct').find({
        query: {
          $or: [
            { 'owner.lastname': { $regex: '^' + name, $options: 'i' } },
            { 'owner.firstname': { $regex: '^' + name, $options: 'i' } }
          ]
        }
      })
    },
    searchWorkshopByName (name) {
      const workshop = this.$store.dispatch('workshops/find', {
        query: {
          $limit: 25,
          $or: [
            { 'address.NAME.NAM': { $regex: '^' + name, $options: 'i' } },
            { 'address.NAME.VOR': { $regex: '^' + name, $options: 'i' } }
          ]
        }
      })
      return workshop
    },
    async setCars (owner) {
      const orders = await this.$store.dispatch('orders/find', {
        query: {
          'owner.firstname': owner.firstname,
          'owner.lastname': owner.lastname,
          'owner.street': owner.street,
          'owner.country.name': owner.country.name,
          'owner.city.name': owner.city.name,
          'owner.city.zip': owner.city.zip
        }
      })
      orders.data.map(order => this.cars.push({ label: `${order.car.producer} ${order.car.type}`, value: order._id }))
    },
    deleteOrder () {
      this.orderVisible = false
      this.$delete(this.damageReport.FIELDS, 'orderId')
      this.cars = []
    },
    setWorkshop (workshop) {
      this.damageReport.FIELDS.workshopId = workshop._id
    },
    setTime () {
      this.damageReport.SCHADEN.SDA = combineDateTime(this.damageReport.SCHADEN.SDA, this.timeHours, this.timeMinutes)
    }
  }
}
</script>
